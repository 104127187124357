import React, { useState } from 'react';
import { FaExternalLinkAlt, FaTimes } from 'react-icons/fa';
import { motion } from 'framer-motion';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';
import Slider from 'react-slick';

const ProjectCard = ({ project }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
  };

  return (
    <>
      <motion.div
        className="project-card relative bg-white dark:bg-gray-700 bg-opacity-20 backdrop-filter backdrop-blur-lg rounded-lg shadow-lg p-4 hover:scale-105 transition-transform flex flex-col justify-between"
        onClick={openModal}
      >
        <div>
          <div className="project-image-container mb-2">
            <img src={`./img/${project.images[0]}`} alt={project.title} className="w-full h-48 object-cover rounded-lg" />
          </div>
          <div className="project-tags flex flex-wrap gap-2 mb-2 justify-center">
            {project.tags.map((tag, idx) => (
              <span key={idx} className="tag bg-gray-200 dark:bg-gray-600 p-2 rounded-full flex items-center text-xs">
                <img src={`https://cdn.jsdelivr.net/gh/devicons/devicon/icons/${tag.icon}/${tag.icon}-original.svg`} className="w-4 h-4 mr-2" alt={tag.name} />
                {tag.name}
              </span>
            ))}
          </div>
          <div className="project-content text-gray-900 dark:text-gray-100">
            <h3 className="project-title text-xl font-bold mb-2">{project.title}</h3>
            <p className="project-description mb-4">{project.description}</p>
          </div>
        </div>
        <div>
          <button className="btn btn-primary bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 flex items-center w-full justify-center">
            {t('Voir Plus')} <FaExternalLinkAlt className="ml-2" />
          </button>
        </div>
      </motion.div>
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        className="project-modal"
        overlayClassName="project-modal-overlay fixed inset-0 flex items-center justify-center"
        ariaHideApp={false}
      >
        <motion.div
          className="modal-content bg-white bg-opacity-90 backdrop-filter backdrop-blur-lg p-6 rounded-lg shadow-lg relative max-h-screen overflow-y-auto"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 50 }}
        >
          <button onClick={closeModal} className="btn-close text-black absolute top-2 right-2">
            <FaTimes size={24} />
          </button>
          <h2 className="modal-title text-2xl font-bold mb-4">{project.title}</h2>
          <p className="modal-description mb-4">{project.fullDescription}</p>
          <div className="modal-carousel mb-4">
            <Slider {...settings}>
              {project.images.map((image, index) => (
                <img key={index} src={`./img/${image}`} alt={`${project.title} ${index + 1}`} className="w-full object-cover rounded-lg" />
              ))}
            </Slider>
          </div>
          <p className="modal-date text-gray-600 mb-4">{project.date}</p>
          {project.links && (
            <div className="modal-links flex flex-wrap gap-2">
              {project.links.map((link, idx) => (
                <a key={idx} href={link.url} target="_blank" rel="noopener noreferrer" className="modal-link bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600">
                  {link.label}
                </a>
              ))}
            </div>
          )}
        </motion.div>
      </Modal>
    </>
  );
};

export default ProjectCard;
