const logotext = "Rémi";
const socialprofils = {
  github: "https://github.com/MrLilian24",
  gitea: "https://codefirst.iut.uca.fr/git/remi.lavergne",
  linkedin: "https://www.linkedin.com/in/rémi-lavergne-49a552250/",
  leetcode: "https://leetcode.com/u/mrlilian24/"
};
export {
  logotext,
  socialprofils,
};
