import React from 'react';
import DiscordCard from './DiscordCard';
import '../styles/about.css';
import { useTranslation } from 'react-i18next';

const About = () => {
  const { t } = useTranslation();

  return (
    <section id="about" className="py-20 bg-light text-dark dark:bg-dark dark:text-light flex justify-center items-center">
      <div className="container mx-auto px-6 flex flex-col lg:flex-row items-center">
        <div className="lg:w-1/3 w-full mb-8 lg:mb-0 flex justify-center lg:justify-start text-left">
          <DiscordCard/>
        </div>
        <div className="lg:w-2/3 w-full lg:pl-12 text-center lg:text-left">
          <h2 className="text-4xl font-bold mb-4">{t('AboutMe.Title')}</h2>
          <p className="text-lg mb-6">{t('AboutMe.Description')}</p>
          <p className="text-lg mb-6">{t('AboutMe.Description2')}</p>
        </div>
      </div>
    </section>
  );
};

export default About;

/*
<h3 className="text-2xl font-semibold mb-4">{t('about.hobbiesTitle')}</h3>
<div className="flex flex-wrap justify-center lg:justify-start gap-4">
  <span className="hobby-card bg-red-500 text-white rounded-full px-4 py-2">🎮 Gaming</span>
  <span className="hobby-card bg-green-500 text-white rounded-full px-4 py-2">💻 Coding</span>
  <span className="hobby-card bg-blue-500 text-white rounded-full px-4 py-2">🎵 Music</span>
  <span className="hobby-card bg-purple-500 text-white rounded-full px-4 py-2">✈️ Travel</span>
  <span className="hobby-card bg-yellow-500 text-white rounded-full px-4 py-2">📸 Photography</span>
</div>
*/