import React, { useState, useRef, useEffect } from 'react';
import { FaSun, FaMoon, FaGlobe } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-scroll';
//import Logo from '../assets/logo.svg';

const Navbar = ({ toggleTheme, theme }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [languageMenuOpen, setLanguageMenuOpen] = useState(false);
  const { t, i18n } = useTranslation();
  const languageMenuRef = useRef(null);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setLanguageMenuOpen(false); // Close the language menu after selecting a language
  };

  const handleLanguageToggle = () => {
    setLanguageMenuOpen(!languageMenuOpen);
  };

  // Close language menu if clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (languageMenuRef.current && !languageMenuRef.current.contains(event.target)) {
        setLanguageMenuOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <nav className="bg-gray-100 dark:bg-gray-800 text-gray-900 dark:text-gray-100 shadow-md fixed w-full z-10">
      <div className="container mx-auto px-4 py-4 flex justify-between items-center">
        <div className="flex items-center">
          <Link to="home" smooth duration={100} className="text-2xl font-bold cursor-pointer">
            Rémi.L
          </Link>
        </div>
        <div className="hidden md:flex space-x-6">
          <Link to="about" smooth duration={100} className="hover:text-blue-500 dark:hover:text-blue-300 cursor-pointer">
            {t('About')}
          </Link>
          <Link to="projects" smooth duration={100} className="hover:text-blue-500 dark:hover:text-blue-300 cursor-pointer">
            {t('Projects')}
          </Link>
          <Link to="experience" smooth duration={100} className="hover:text-blue-500 dark:hover:text-blue-300 cursor-pointer">
            {t('Experience')}
          </Link>
          <Link to="skills" smooth duration={100} className="hover:text-blue-500 dark:hover:text-blue-300 cursor-pointer">
            {t('Skills')}
          </Link>
          <Link to="footer" smooth duration={100} className="hover:text-blue-500 dark:hover:text-blue-300 cursor-pointer">
            {t('Contact')}
          </Link>
        </div>
        <div className="flex space-x-4 items-center">
          <button
            onClick={toggleTheme}
            className="flex items-center space-x-2 bg-gray-200 dark:bg-gray-700 p-2 rounded-lg focus:outline-none hover:bg-gray-300 dark:hover:bg-gray-600"
          >
            {theme === 'dark' ? <FaSun className="text-yellow-500" /> : <FaMoon />}
            <span>{theme === 'dark' ? t('Light Mode') : t('Dark Mode')}</span>
          </button>
          <div className="relative" ref={languageMenuRef}>
            <button
              onClick={handleLanguageToggle}
              className="flex items-center space-x-2 bg-gray-200 dark:bg-gray-700 p-2 rounded-lg focus:outline-none hover:bg-gray-300 dark:hover:bg-gray-600"
            >
              <FaGlobe />
              <span>{t('Language')}</span>
            </button>
            {languageMenuOpen && (
              <div className="absolute right-0 mt-2 w-32 bg-white dark:bg-gray-800 rounded-lg shadow-lg">
                <button
                  onClick={() => changeLanguage('en')}
                  className="w-full text-left px-4 py-2 hover:bg-gray-200 dark:hover:bg-gray-700 focus:outline-none"
                >
                  English
                </button>
                <button
                  onClick={() => changeLanguage('fr')}
                  className="w-full text-left px-4 py-2 hover:bg-gray-200 dark:hover:bg-gray-700 focus:outline-none"
                >
                  Français
                </button>
              </div>
            )}
          </div>
        </div>
        <div className="md:hidden flex items-center">
          <button onClick={handleToggle} className="focus:outline-none">
            <svg className="w-6 h-6 text-gray-900 dark:text-gray-100" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d={isOpen ? 'M6 18L18 6M6 6l12 12' : 'M4 6h16M4 12h16m-7 6h7'}></path>
            </svg>
          </button>
        </div>
      </div>
      {isOpen && (
        <div className="md:hidden">
          <div className="flex flex-col space-y-4 pb-4">
            <Link to="about" smooth duration={500} onClick={handleToggle} className="hover:text-blue-500 dark:hover:text-blue-300 cursor-pointer">
              {t('About')}
            </Link>
            <Link to="projects" smooth duration={500} onClick={handleToggle} className="hover:text-blue-500 dark:hover:text-blue-300 cursor-pointer">
              {t('Projects')}
            </Link>
            <Link to="experience" smooth duration={500} onClick={handleToggle} className="hover:text-blue-500 dark:hover:text-blue-300 cursor-pointer">
              {t('Experience')}
            </Link>
            <Link to="skills" smooth duration={500} onClick={handleToggle} className="hover:text-blue-500 dark:hover:text-blue-300 cursor-pointer">
              {t('Skills')}
            </Link>
            <Link to="footer" smooth duration={500} onClick={handleToggle} className="hover:text-blue-500 dark:hover:text-blue-300 cursor-pointer">
              {t('Contact')}
            </Link>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
