import React from 'react';
import { FaGithub, FaLinkedin } from 'react-icons/fa';
import { SiLeetcode, SiGitea } from "react-icons/si";
import { logotext, socialprofils } from '../content_option';
import '../styles/style.css';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-scroll';


const Header = () => {
  const { t } = useTranslation();

  return (
    <header id="home" className="header-fullscreen bg-light dark:bg-dark text-dark dark:text-light">
      <div className="header-content">
        <div className="social-icons">
          <ul>
            <li>
              <a href={socialprofils.github} target="_blank" rel="noopener noreferrer">
                <FaGithub />
              </a>
            </li>
            <li>
              <a href={socialprofils.gitea} target="_blank" rel="noopener noreferrer">
                <SiGitea />
              </a>
            </li>
            <li>
              <a href={socialprofils.linkedin} target="_blank" rel="noopener noreferrer">
                <FaLinkedin />
              </a>
            </li>
            <li>
              <a href={socialprofils.leetcode} target="_blank" rel="noopener noreferrer">
                <SiLeetcode />
              </a>
            </li>
          </ul>
          <p>{t('Follow Me')}</p>
        </div>
        <div className="intro-text">
          <h1>{t('Header.Greeting')}</h1>
          <p>{t('Header.SubGreeting')}</p>
          <div className="intro-buttons">
            <a href="#projects" className="btn primary-btn">{t('Header.ViewWork')}</a>
            <a href="./media/CV.pdf" target='_blank' className="btn secondary-btn">{t('Header.DownloadCV')}</a>
          </div>
        </div>
      </div>
      <div className="header-background">
        <img src="./img/first.jpg" alt="Rémi" />
      </div>
    </header>
  );
};


export default Header;
