import logo from './logo.svg';
import React from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './App.css';
import './index.css';
import './styles/style.css';
import './styles/discordCard.css';
import './styles/about.css';
import './styles/projects.css';
import { ThemeProvider } from './ThemeContext';
import './i18n';


import Header from './components/Header';
import About from './components/About';
import Experience from './components/Experience';
import Projects from './components/Projects';
import ProjectCard from './components/ProjectCard';
import Skills from './components/Skills';
import Footer from './components/Footer';
import Navbar from './components/Navbar';
import './styles/navbar.css';


function App() {
  const [theme, setTheme] = React.useState('light');

  const toggleTheme = () => {
    setTheme(theme === 'light' ? 'dark' : 'light');
    document.documentElement.classList.toggle('dark');
  };


  return (
    <ThemeProvider>
      <div className={`App ${theme === 'dark' ? 'dark' : ''}`}>
        <Navbar toggleTheme={toggleTheme} theme={theme} />
        <Header />
        <About />
        <Projects />
        <Experience />
        <Skills />
        <Footer />
      </div>
    </ThemeProvider>
  );
}

export default App;
