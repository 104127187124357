import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../styles/experience.css';

const experiencesData = [
  {
    title: "Auxiliaire de Vacances",
    company: "Société Générale",
    companyUrl: "https://societegenerale.com",
    location: "Aurillac, France",
    startDate: "Juillet 2024",
    endDate: "Août 2024",
    duration: "1 mois",
    description: "Fonctionnement des banques et procédures bancaires (Système d'Information et SDO). Accueil et support aux clients en agence.",
  },
  {
    title: "Auxiliaire de Vacances",
    company: "BNP Paribas",
    companyUrl: "https://bnpparibas.com",
    location: "Clermont-Ferrand, France",
    startDate: "Février 2024",
    endDate: "Mars 2024",
    duration: "1 mois",
    description: "Fonctionnement des banques et procédures bancaires (Système d'Information et SDO). Accueil et support aux clients en agence.",
  },
  {
    title: "Employé Polyvalent",
    company: "Intermarché",
    companyUrl: "https://intermarche.com",
    location: "Aurillac, France",
    startDate: "Juin 2023",
    endDate: "Août 2023",
    duration: "3 mois",
    description: "Service aux rayons traditionnels (Traiteur - Charcuterie - Fromagerie - Poissonnerie) et mise en rayon.",
  },
  {
    title: "Boulangerie / Hôte de Caisse",
    company: "E.Leclerc",
    companyUrl: "https://www.e.leclerc",
    location: "Aurillac, France",
    startDate: "Décembre 2022",
    endDate: "Février 2023",
    duration: "3 mois",
    description: "- Gestion des procédures d'encaissement - Cuisson et mise en rayon boulangerie",
  },
];

const educationData = [
  {
    title: "BUT Informatique",
    school: "Université Clermont Auvergne",
    startDate: "Septembre 2022",
    endDate: "Actuellement",
    description: "Focalisé sur le développement d'application mobile, de base de données, système & architecture, réseau et web. Et bien d'autres concernant la programmation comme la gestion de projet ou d'équipe.",
  },
  {
    title: "Baccalauréat Général",
    school: "Lycée Monnet-Mermoz",
    startDate: "Septembre 2019",
    endDate: "Juin 2022",
    description: "Spécialités Mathématiques, Numérique et Sciences Informatiques (NSI) et Sciences Economiques et Sociales (SES).",
  },
];

const Experience = () => {
  const [selectedExperience, setSelectedExperience] = useState(0);
  const { t } = useTranslation();

  return (
    <section id="experience" className="experience-section py-16 bg-gray-100 dark:bg-gray-800 text-gray-900 dark:text-gray-100">
      <div className="container mx-auto px-4 flex flex-col lg:flex-row">
        <div className="lg:w-3/5 lg:pr-8">
          <h2 className="section-title text-4xl font-bold mb-8">{t('Experience')}</h2>
          <div className="flex flex-col lg:flex-row">
            <div className="lg:w-1/3">
              <div className="experience-list">
                {experiencesData.map((experience, index) => (
                  <div
                    key={index}
                    className={`experience-company ${selectedExperience === index ? 'selected' : ''}`}
                    onClick={() => setSelectedExperience(index)}
                  >
                    <h3 className="text-l font-normal mb-2 p-1 pt-2 pb-2">{experience.company}</h3>
                  </div>
                ))}
              </div>
            </div>
            <div className="lg:w-2/3 lg:pl-4">
              <div className="experience-details border-l-4 border-blue-500 pl-4">
                <h3 className="text-xl font-normal mb-1">
                  {experiencesData[selectedExperience].title}
                  <span
                    className="text-xl font-bold company-link"
                    onClick={() => window.open(experiencesData[selectedExperience].companyUrl, '_blank')}
                  >
                    {' '} @ {experiencesData[selectedExperience].company}
                  </span>
                </h3>
                <p className="text-sm text-gray-400 dark:text-gray-300">{experiencesData[selectedExperience].location}</p>
                <p className="text-sm text-gray-400 dark:text-gray-300 mb-4">{experiencesData[selectedExperience].startDate} – {experiencesData[selectedExperience].endDate} • ({experiencesData[selectedExperience].duration})</p>
                <p className="text-sm mt-2">{experiencesData[selectedExperience].description}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="lg:w-2/5 lg:pl-8 mt-8 lg:mt-0">
          <h2 className="section-title text-4xl font-bold mb-8">{t('Education')}</h2>
          <div className="timeline">
            {educationData.map((education, index) => (
              <div className="timeline-item" key={index}>
                <div className="timeline-dot"></div>
                <div className="timeline-content bg-white dark:bg-gray-700 bg-opacity-10 backdrop-filter backdrop-blur-lg rounded-lg shadow-lg p-4 mb-4">
                  <h3 className="text-xl font-bold mb-2">{education.title}</h3>
                  <h4 className="text-lg mb-2">{education.school}</h4>
                  <p className="text-sm text-gray-400 dark:text-gray-300 mb-4">{education.startDate} - {education.endDate}</p>
                  <p className="mb-4">{education.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Experience;